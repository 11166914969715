import React from "react"
import { Row } from 'react-bootstrap'
import LogoDark from "../images/logodark.inline.svg"
import { Link } from "gatsby"

const Footer = () => {
  return (
<footer className="footer">
    <div className="container">
        <div className="footer__widgets">
            <Row>
                <div className="col-lg-3 col-md-6">
                    <div className="widget widget-about-us">
                    <Link to="/" className="logo-container flex-child">
                    <LogoDark/>
                  </Link>
    
                        <p className="mt-24 mb-32">Grow your dagcoins with confidence.</p>
                        
                        <div className="socials"> <a href="https://www.facebook.com/dagcoin/" className="social social-facebook" aria-label="facebook" title="facebook" target="_blank"><i className="ui-facebook"></i></a> <a href="https://www.instagram.com/dagcoin/" className="social social-instagram" aria-label="instagram" title="instagram" target="_blank"><i className="ui-instagram"></i></a> <a href="https://www.youtube.com/channel/UChNN88DpmBNZUmkDhjs81NQ/featured" className="social social-youtube" aria-label="youtube" title="youtube" target="_blank"><i className="ui-youtube"></i></a></div>
                    </div>
                </div>
                <div className="col-lg-3 offset-lg-3 col-md-6">
                    <div className="widget widget_nav_menu">
                        <h5 className="widget-title">About Dagcoin</h5>
                        <ul>
                            <li><a href="https://dagcoin.org/wallet/" target="_blank">Download DagWallet</a></li>
                            <li><a href="https://wallet.dagcoin.org/#/intro" target="_blank">WebWallet</a></li>
                            <li><a href="https://merchant.dagcoin.org/" target="_blank">Merchant Finder</a></li>
                            <li><a href="https://dagpay.io/" target="_blank">Dagcoin for Merchants</a></li>
                        </ul>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="widget widget_nav_menu">
                        <h5 className="widget-title">Legal</h5>
                        <ul>
               
                            <li><Link to="/privacy-policy">
                            Privacy Policy
                              </Link></li>
                              <li><Link to="/terms-and-conditions">
                              Terms and conditions
                              </Link></li>
                        </ul>
                    </div>
                </div>
            </Row>
        </div>
    </div>
    <div className="footer__bottom top-divider">
        <div className="container text-center"> <span className="copyright"> © 2020 <a href="https://grow.dagcoin.org">DagcoinGrow</a> </span></div>
    </div>
</footer>
  )
}

export const query = graphql`
  query($lang: String) {
    wordpressPage(
      slug: {eq: "home"}
      wpml_current_locale: {eq: $lang}
    ) {
    acf {
      faq_content
      faq_content_1
      didnt_find_an_answer_go_to_support_page
      faq_title_3
      faq_content_3
      faq_content_9
      faq_content_2
      faq_content_4
      faq_content_5
      faq_content_6
      faq_content_7
      faq_content_8
      faq_title
      faq_title_1
      faq_title_2
      faq_title_4
      faq_title_5
      faq_title_6
      faq_title_7
      faq_title_9
      faq_title_8
      frequently_asked_questions
      grow_tile
      home_desc
      home_title
      how_1_desc
      how_1_title
      how_2_desc
      how_3_desc
      how_2_title
      how_3_title
      how_do_grow
      how_it_works
      how_to_desc
      piority_desc
      piority_title
      sign_up
      start_growing
      start_now
      time_to_start
      time_to_start_1_desc
      time_to_start_1_title
      time_to_start_2_desc
      time_to_start_2_title
      time_to_start_3_desc
      time_to_start_3_title
    }
      title
      yoast_meta {
        name
        content
        property
      }
      yoast_title
        wpml_current_locale
    }
    Videoimg: file(relativePath: { eq: "video-min.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }


    SendAs: file(relativePath: { eq: "mac2.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    GetAs: file(relativePath: { eq: "card2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    play: file(relativePath: { eq: "play-button.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    right: file(relativePath: { eq: "right.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    Hand: file(relativePath: { eq: "hand.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    Custom_one: file(relativePath: { eq: "1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    Custom_two: file(relativePath: { eq: "2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    Custom_three: file(relativePath: { eq: "3.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }    
    Custom_four: file(relativePath: { eq: "4.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    arrow_right: file(relativePath: { eq: "right.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }


  }
`
export default Footer